/** @format */

import React from "react";

const Draggable = ({ children, id, index, onDragEnd }) => {
	const handleDragStart = (e) => {
		e.dataTransfer.setData("text/plain", id);
	};
	const handleDragEnd = () => {
		onDragEnd(index);
	};

	return (
		<div
			draggable
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
			style={{ cursor: "grab" }}
		>
			{children}
		</div>
	);
};

export default Draggable;
