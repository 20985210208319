/** @format */

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Draggable from "./Draggable";
import { DashboardContext } from "../../contexts/DashboardContext";

const StyleNameChangeWrapper = styled("div")({
	display: "flex",
	justifyContent: "space-between",
	marginTop: "10px",
	columnGap: "10px",
});

function ProductImagesPreview({ productImages, removeDroppedImage, selectedGroupId }) {
	const [fileNames, setFileNames] = useState([]);
	const { setImagesWithNames } = React.useContext(DashboardContext);

	useEffect(() => {
		const names = productImages.map((productImage) => {
			return productImage.name;
		});
		setFileNames(names);
	}, [productImages, selectedGroupId, setImagesWithNames]);

	useEffect(() => {
		return () => {
			productImages.forEach((image) => URL.revokeObjectURL(URL.createObjectURL(image)));
		};
	}, [productImages]);

	if (productImages.length === 0) {
		return (
			<div
				style={{
					marginTop: "25px",
					color: "orange",
				}}
			>
				Trenutno je prazna. Nakon odabira slika, pojaviće se ovde i potom ih možete prevući u levu
				listu proizvoda.
			</div>
		);
	}

	return (
		<div>
			{productImages?.map((productImage, index) => (
				<Draggable
					key={index}
					id={URL.createObjectURL(productImage)}
					index={index}
					onDragEnd={removeDroppedImage}
					// onDragEnd={(removeDroppedImage, () => console.log("Drag ended", fileNames[index]))}
				>
					<div style={{ width: "100%", margin: "10px 0", borderBottom: "1px solid orange" }}>
						<img
							style={{ width: "100%" }}
							src={URL.createObjectURL(productImage)}
							alt="Preview"
						/>
						<StyleNameChangeWrapper>
							<span style={{ paddingBottom: "5px" }}>{fileNames[index]}</span>
						</StyleNameChangeWrapper>
					</div>
				</Draggable>
			))}
		</div>
	);
}

export default ProductImagesPreview;
