/** @format */

import React, { useEffect, useState, forwardRef } from "react";
import { useGetProductsForDashboardQuery } from "../../api/testApi";
import { skipToken } from "@reduxjs/toolkit/query/react";
import Droppable from "./Droppable";

const ProductsList = forwardRef(({ selectedGroupId, setValue }, ref) => {
	const { data: products, isSuccess } = useGetProductsForDashboardQuery(
		selectedGroupId ? selectedGroupId : skipToken,
	);

	const [productImages, setProductImages] = useState({});
	const [productFiles, setProductFiles] = useState({});

	const blobUrlToFile = async (blobUrl, filename) => {
		const response = await fetch(blobUrl);
		const blob = await response.blob();
		const fileExtension = blob.type.split("/")[1];
		return { file: new File([blob], filename, { type: blob.type }), fileExtension };
	};

	const handleDrop = async (productId, imageSrc) => {
		const { file, fileExtension } = await blobUrlToFile(imageSrc, `${productId}`);
		setProductImages((prev) => ({
			...prev,
			[productId]: { src: imageSrc },
		}));
		setProductFiles((prev) => ({
			...prev,
			[productId]: new File([file], `${productId}.${fileExtension}`, { type: file.type }),
		}));
	};

	useEffect(() => {
		setValue("productImages", productFiles);
	}, [productFiles, setValue]);

	return (
		<>
			<div>Lista proizvoda</div>
			<div style={{ marginBottom: "10px" }}>ID odabrane grupe: {selectedGroupId}</div>
			{isSuccess && (
				<>
					{products.map((product) => (
						<Droppable
							key={product.ID_broj}
							onDrop={(imageSrc) => handleDrop(product.ID_broj, imageSrc)}
							isImageDropped={!!productImages[product.ID_broj]}
						>
							<div>{product.Naziv}</div>
							{productImages[product.ID_broj] && (
								<img
									src={productImages[product.ID_broj].src}
									alt={product.Naziv}
									style={{ display: "block", marginTop: "10px", maxWidth: "100%" }}
								/>
							)}
						</Droppable>
					))}
				</>
			)}
		</>
	);
});

export default ProductsList;
