/** @format */

import { styled } from "@mui/material/styles";

const StyledLabelTitle = styled("span")(({ disabled }) => ({
	fontSize: "0.8rem",
	color: disabled ? "#aa6c42" : "orange",
	position: "relative",
	top: "9px",
	left: "20px",
	backgroundColor: "black",
	padding: "0 5px",
	zIndex: "1",
}));

const StyledRequired = styled("span")({
	color: "red",
});

const Label = ({ labelTitle, required, disabled }) => {
	return (
		<StyledLabelTitle disabled={disabled}>
			{labelTitle}
			{required && <StyledRequired> *</StyledRequired>}
		</StyledLabelTitle>
	);
};

export default Label;
