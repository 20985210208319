/** @format */

import React from "react";
import styled from "styled-components";
import Modal from "./PDFExcelModal";
// import { useGetPriceListQuery } from "../api/testApi.js"; // ! NOW WE USE REAL DATA FROM API
import { useGetPriceListQuery } from "../api/ordersApi.js"; // ! NOW WE USE REAL DATA FROM API
import { LoadingNotificationBox, ErrorNotificationBox } from "../shared/styled/NotificationBox.js";

const PriceListContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	align-items: center;
	color: black;
	overflow: hidden;
`;
const PriceListCard = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: calc(25% - 20px);
	align-items: center;
	justify-content: center;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	box-sizing: border-box;
	padding: 10px;
	margin: 10px;
	/* margin: calc(5%); */
	min-width: 150px;
	min-height: fit-content;
	/* width: -webkit-fill-available; */ /* if uncommented cards will grow in size until the they fill the screen */
	max-width: 150px;
	aspect-ratio: 1/1;
	cursor: pointer;
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	text-align: center;
	height: -webkit-fill-available;
`;

const PriceList = () => {
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [modalData, setModalData] = React.useState(null);
	const { data: priceList, isLoading, isError } = useGetPriceListQuery();

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	if (isLoading) {
		// render a loading indicator here
		return <LoadingNotificationBox>Loading...</LoadingNotificationBox>;
	}

	if (isError) {
		// render an error message here
		return <ErrorNotificationBox>Error loading data</ErrorNotificationBox>;
	}

	return (
		<>
			<Modal
				text="Izaberite tip fajla za export."
				isOpen={isModalOpen}
				handleClose={closeModal}
				data={modalData}
			/>
			<PriceListContainer>
				{priceList.map((item) => (
					<PriceListCard
						key={item.id}
						onClick={() => {
							setModalData(item);
							openModal();
						}}
					>
						<Content>
							<img
								src={item.logo}
								alt="logo"
								width={100}
							></img>
							<span>{item.name}</span>
						</Content>
					</PriceListCard>
				))}
			</PriceListContainer>
		</>
	);
};

export default PriceList;
