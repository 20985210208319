/** @format */

import { forwardRef } from "react";
import { styled } from "@mui/system";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Label from "./Label";
import FormFieldDescription from "./FormFieldDescription";
import FormFieldAndDescriptionWrapper from "./FormFieldAndDescriptionWrapper";

const blue = {
	100: "#DAECFF",
	200: "#b6daff",
	400: "#3399FF",
	500: "#007FFF",
	600: "#0072E5",
	900: "#003A75",
};

const grey = {
	50: "#F3F6F9",
	100: "#E5EAF2",
	200: "#DAE2ED",
	300: "#C7D0DD",
	400: "#B0B8C4",
	500: "#9DA8B7",
	600: "#6B7A90",
	700: "#434D5B",
	800: "#303740",
	900: "#1C2025",
};

const StyledTextareaAutosize = styled(TextareaAutosize)(
	({ theme, disabled }) => `
	width: 100%;
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
	min-height: 73px;
  padding: 8px 12px;
  border-radius: 8px;
	background: ${disabled ? "#141414" : theme.palette.mode === "dark" ? grey[900] : "black"};
	color: ${disabled ? "#664424" : "orange"};
	border: ${disabled ? `1px solid #242424` : `1px solid orange`};
  // box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
	resize: ${disabled ? "none" : "vertical"};

  &:hover {
		border-color: ${disabled ? `${grey[400]}` : `white`};
		cursor: ${disabled && "not-allowed"};
  }

  &:focus {
    border: ${blue[600]};
    box-shadow: 0 0 0 2px ${
			theme.palette.mode === "dark" ? blue[600] : blue[600]
		}; // TODO: Change the color from blue to some variant of orange
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

	/* Style the placeholder */
  &::placeholder {
    font-size: 1rem; /* Adjust the placeholder font size */
  }
`,
);

const StyledTextareaWrapper = styled("div")({
	width: "-webkit-fill-available",
});

const RHFTextarea = forwardRef((props, ref) => {
	return (
		<>
			<Label
				labelTitle="Opis grupe proizvoda"
				disabled={props.disabled}
			/>
			<FormFieldAndDescriptionWrapper>
				<StyledTextareaWrapper style={{ width: "-webkit-fill-available" }}>
					<StyledTextareaAutosize
						ref={ref}
						{...props}
						aria-label="empty textarea"
						placeholder="Unesi kratak opis grupe proizvoda"
						disabled={props.disabled}
					/>
				</StyledTextareaWrapper>
				<FormFieldDescription descriptionText="Popunjavanjem polja na stranici proizvoda dodatno će se pojaviti opis ispod naziva grupe proizvoda." />
			</FormFieldAndDescriptionWrapper>
		</>
	);
});

export default RHFTextarea;
