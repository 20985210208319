/** @format */

import React from "react";
import { Page, Text, Image, Font, View, Document, StyleSheet } from "@react-pdf/renderer";
import Roboto from "./Roboto-Regular.ttf";
import RobotoBold from "./Roboto-Black.ttf";
import logoImage from "../images/logo_CH_black.png";

const PDFExportInvoices = (data) => {
	// TODO: dodati u naslov broj fakture, naziv kupca i datum (Pregled fakture - 1234 - marko - 12.12.2021)
	// const currentDate = new Date();
	// const month = currentDate.getMonth() + 1;
	// const formattedMonth = month < 10 ? `0${month}` : month;
	// const formattedDate = `${currentDate.getDate()}.${formattedMonth}.${currentDate.getFullYear()}`;

	function performCalculations(values) {
		let sumValues = 0;

		if (!values) return [0, 0, 0];

		values.forEach((array) => {
			const lastIndex = array[array.length - 1];
			const index9Value = parseFloat(lastIndex.replace(/[^\d,-]/g, "").replace(",", "."));
			sumValues += index9Value;
		});

		const valueWithPdv = sumValues.toLocaleString("sr-RS", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		const lastIndexValue = parseFloat(values[0][7].replace(/[^\d,-]/g, "").replace(",", "."));
		const valueOnly = (sumValues / (1 + lastIndexValue / 100)).toLocaleString("sr-RS", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		const pdvOnly = (
			(sumValues / (1 + lastIndexValue / 100)) *
			(lastIndexValue / 100)
		).toLocaleString("sr-RS", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});

		return [valueWithPdv, valueOnly, pdvOnly];
	}

	return (
		<Document>
			<Page style={styles.body}>
				<View style={styles.headerWrapper}>
					<Image
						style={styles.image}
						src={logoImage}
					/>
					<View style={styles.accountsDataWrapper}>
						<Text style={styles.accountsData}>
							Tekući računi: 265-1100310000387-52, Raiffeisen, 220-159317-60 ProCredit,
						</Text>
						<Text>250-1530000395030-72 Eurobanka, 325-9500500368445-79 OTP</Text>
						<Text>Kontakt telefoni: +381 30/ 570-930; 570-903; 570-100</Text>
						<Text>E-mail: office@central-ch.com</Text>
						<View style={styles.separator} />
						<Text style={styles.accountsStatement}>
							Ukupan upisan kapital: 979.276,85 RSD (novčani: 55.881,01 RSD + 923.395,84 RSD u
							osnovnim sredstvima)
						</Text>
					</View>
				</View>
				<View style={styles.belowHeader}>
					<Text>
						Privredno društvo: "Central-h" d.o.o. Majdanpek - jednočlano društvo sa ograničenom
						odgovornošću. Čekić bb, Debeli lug, 19250 Majdanpek.
					</Text>
					<Text>PIB: 100987249 | Matični broj: 17122002 | Šifra delatnosti: 4674</Text>
				</View>

				{/* MIDDLE DATA */}
				<View style={styles.midDataWrapper}>
					<View style={styles.mid}>
						<View style={styles.midDataTitle}>
							<Text>
								{/* TODO: dodati u naslov broj fakture, naziv kupca i datum (Pregled fakture - 1234 - marko - 12.12.2021) */}
								Pregled fakture
							</Text>
						</View>
					</View>
				</View>

				{/* TABLE */}
				<View style={styles.tableWrapper}>
					<View style={styles.tableHeaderBG}></View>
					{data?.data?.header.map((hItem, hIndex) => {
						return (
							<View key={hIndex}>
								{/* Header */}
								<Text style={styles.tableHeader}>{hItem}</Text>
								{/* Rows */}
								{data?.data?.body.map((bItem, bIndex) => {
									return (
										<Text
											key={bIndex}
											style={{ margin: "5px" }}
										>
											{bItem[hIndex]}
										</Text>
									);
								})}
							</View>
						);
					})}
					<View style={styles.tableFooterBG}></View>
				</View>

				{/* TOTALS */}
				<View style={styles.totalsPosition}>
					<View style={styles.totalsWrapper}>
						<View style={styles.totalsLayout}>
							<Text>Ukupno bez PDV-a:</Text>
							<Text>{performCalculations(data?.data?.body)[1]}</Text>
						</View>
						<View style={styles.totalsLayout}>
							<Text>Iznos PDV-a:</Text>
							<Text>{performCalculations(data?.data?.body)[2]}</Text>
						</View>
						<View style={styles.totalsLayout}>
							<Text>Ukupno sa PDV-om:</Text>
							<Text>{performCalculations(data?.data?.body)[0]}</Text>
						</View>
					</View>
				</View>

				<View style={styles.footer}>
					<Text>WWW.CENTRAL-CH.COM</Text>
				</View>
				{/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed /> */}
			</Page>
		</Document>
	);
};

Font.register({
	family: "Roboto",
	format: "truetype",
	src: Roboto,
});
Font.register({
	family: "RobotoBold",
	format: "truetype",
	src: RobotoBold,
});

const styles = StyleSheet.create({
	body: {
		padding: 20,
	},

	/* HEADER */
	headerWrapper: {
		height: "70px",
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	accountsDataWrapper: {
		display: "flex",
		alignItems: "flex-end",
		textAlign: "right",
		fontSize: 9,
		fontFamily: "Roboto",
	},
	separator: {
		height: 20,
	},
	accountsStatement: {
		fontSize: 6,
	},
	image: {
		height: "100%",
	},
	belowHeader: {
		fontFamily: "Roboto",
		marginTop: 5,
		padding: 4,
		fontSize: 8,
		textAlign: "center",
		backgroundColor: "black",
		color: "white",
	},

	/* FOOTER */
	footer: {
		position: "absolute",
		fontFamily: "RobotoBold",
		fontSize: 14,
		bottom: 20,
		left: 0,
		right: 0,
		textAlign: "center",
		color: "grey",
	},

	/* MID DATA */
	midDataWrapper: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		marginTop: "25px",
		fontFamily: "Roboto",
	},
	midDataTitle: {
		fontFamily: "RobotoBold",
		fontSize: "16px",
		marginBottom: "15px",
	},
	midDataLeft: {
		display: "flex",
		flexDirection: "row",
		fontSize: "12px",
	},
	midDataLeftTitles: {
		fontFamily: "RobotoBold",
	},
	midDataRight: {
		fontFamily: "RobotoBold",
		fontSize: "12px",
		padding: "15px",
		backgroundColor: "lightgrey",
	},

	/* TABLE */
	tableHeader: {
		margin: "5px",
		fontFamily: "RobotoBold",
	},
	tableHeaderBG: {
		position: "absolute",
		top: 0,
		width: "100%",
		height: "20px",
		backgroundColor: "lightgrey",
	},
	tableFooterBG: {
		position: "absolute",
		bottom: "-20px",
		width: "100%",
		height: "20px",
		backgroundColor: "lightgrey",
	},
	tableWrapper: {
		marginTop: "25px",
		fontSize: "10px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		position: "relative",
	},

	/* TOTALS */
	totalsPosition: {
		display: "flex",
		alignItems: "flex-end",
		paddingRight: 30,
	},
	totalsWrapper: {
		marginTop: "50px",
		fontFamily: "Roboto",
		fontSize: 14,
		width: "40%",
	},
	totalsLayout: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
});

// ReactPDF.render(<PDFExportInvoices />);

export default PDFExportInvoices;
