import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const MOCK_API_URL = "https://my-json-server.typicode.com/codepanov/b2b_mock/";

export const testApi = createApi({
	reducerPath: "testApi",
	baseQuery: fetchBaseQuery({ baseUrl: MOCK_API_URL }),
	endpoints: (builder) => ({
		getOrders: builder.query({
			query: () => {
				return "orders"
			},
		}),

		getFinancialState: builder.query({
			query: () => {
				return "financial-state"
			},
		}),
		getInvoices: builder.query({
			query: () => {
				return "invoices"
			},
		}),
		getPriceList: builder.query({
			query: () => {
				return "price-list"
			},
		}),
	}),
});

export const { useGetOrdersQuery, useGetFinancialStateQuery, useGetInvoicesQuery, useGetPriceListQuery } = testApi;