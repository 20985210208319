/** @format */
import styled from "styled-components";

export const LoadingNotificationBox = styled.p`
	background-color: #da790b;
	border-radius: 4px;
	color: #fff;
	margin: auto;
	width: fit-content;
	padding: 0.5rem 1rem;
	border-radius: 5px;
`;

export const ErrorNotificationBox = styled.p`
	background-color: #ad2e2e;
	border-radius: 4px;
	color: #fff;
	margin: auto;
	width: fit-content;
	padding: 0.5rem 1rem;
	border-radius: 5px;
`;
