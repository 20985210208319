/** @format */

import { forwardRef } from "react";
import { Select } from "react-select-virtualized";
import Label from "./Label";
import FormFieldDescription from "./FormFieldDescription";
import FormFieldAndDescriptionWrapper from "./FormFieldAndDescriptionWrapper";
import { useGetGroupsQuery } from "../../api/testApi";

// Function to serialize data
function serializeData(data) {
	return data.map((item) => ({
		value: item.id,
		label: item.name,
	}));
}

const RHFDropdownVirtualized = forwardRef((props, ref) => {
	const { data: rows } = useGetGroupsQuery();

	// Serialize the data
	const serializedData = rows ? serializeData(rows) : [];

	return (
		<>
			<Label
				labelTitle="Grupa proizvoda"
				required
			/>
			<FormFieldAndDescriptionWrapper>
				<Select
					// menuIsOpen
					ref={ref}
					{...props}
					options={serializedData}
					placeholder="Izaberi grupu proizvoda"
					styles={{
						menu: (provided, state) => ({
							...provided,
							/**
							 * Due to minHeight of the StyledWrapper container, the dropdown menu is displaying at the bottom of the container.
							 * That is a large gap there. To fix this, we need to remove the 'top' property from the dropdown menu.
							 * @default top: "100%",
							 */
							top: "unset",
							zIndex: 2,
							backgroundColor: "black",
							color: "orange",
						}),
						container: (provided) => ({
							...provided,
							width: "-webkit-fill-available", // Set the width of the parent container
						}),
						control: (baseStyles, state) => ({
							...baseStyles,
							borderColor: state.isFocused ? "yellow" : "orange",
							borderRadius: "7px",
							height: "39px",
							width: "100%",
							backgroundColor: "black",
						}),
						input: (baseStyles) => ({
							...baseStyles,
							color: "black", // Hack: There is a cursor blinking in the input field. So I changed the color to black.
						}),
					}}
					// removed 'x' button from the dropdown due to difficulties with the resetting of the dropdown after removal of an item
					isClearable={false}
				/>
				<FormFieldDescription
					descriptionText="Odabirom grupe proizvoda omogućavate dodavanje ostalih podataka za odabranu grupu
					proizvoda."
				/>
			</FormFieldAndDescriptionWrapper>
		</>
	);
});

export default RHFDropdownVirtualized;
