/** @format */

import React from "react";
import { Snackbar } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SnackbarNotification = ({ open, onClose, message }) => {
	return (
		<Snackbar
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			open={open}
			autoHideDuration={3000}
			onClose={onClose}
			message={
				<span style={{ display: "flex", alignItems: "center" }}>
					<CheckCircleIcon style={{ color: "green", marginRight: "8px" }} />
					{message}
				</span>
			}
			// sx={{
			// 	"& .MuiSnackbarContent-root": {
			// 		marginTop: "10px",
			// 		marginRight: "250px",
			// 	},
			// }}
		/>
	);
};

export default SnackbarNotification;
