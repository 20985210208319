/** @format */

import { useEffect } from "react";
import FormFieldDescription from "./FormFieldDescription";
import FormFieldAndDescriptionWrapper from "./FormFieldAndDescriptionWrapper";

function GroupImagePreview({ selectedImage, onSave, selectedGroupId }) {
	useEffect(() => {
		if (selectedImage) {
			const originalExtension = selectedImage.name.split(".").pop();
			const updatedFileName = `${selectedGroupId}.${originalExtension}`;
			// Create a new Blob with the updated name
			const updatedBlob = new Blob([selectedImage], { type: selectedImage.type });
			updatedBlob.extension = originalExtension;
			updatedBlob.name = updatedFileName;
			onSave(updatedBlob); // Save the updated Blob and add to form data
		}
	}, [selectedImage, selectedGroupId, onSave]);

	return (
		<FormFieldAndDescriptionWrapper>
			<div style={{ width: "-webkit-fill-available" }}>
				{selectedImage && (
					<div style={{ maxWidth: "100%", marginTop: "6px" }}>
						<img
							style={{ width: "100%" }}
							src={URL.createObjectURL(selectedImage)}
							alt="Preview"
						/>
					</div>
				)}
			</div>
			<FormFieldDescription descriptionText="" />
		</FormFieldAndDescriptionWrapper>
	);
}

export default GroupImagePreview;
