/** @format */

import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
//Import Material React Table Translations
import { MRT_Localization_SR_LATN_RS } from "../utils/MRT_Localization_SR_LATN_RS.js";
import Chip from "@mui/material/Chip";
import { SideDetailsContext } from "../contexts/SideDetailsContext";
import { formatAddress, formatCurrency } from "../shared/utils/formatting.js";
import { totalValue } from "../shared/utils/totals.js";
import { formatDate } from "../shared/utils/formatting.js";
// import { rows } from "../temp_data/table_data";
// import { useGetOrdersQuery } from "../api/testApi.js"; // ! NOW WE USE REAL DATA FROM API
import { useGetOrdersQuery } from "../api/ordersApi.js"; // ! NOW WE USE REAL DATA FROM API
import { LoadingNotificationBox, ErrorNotificationBox } from "../shared/styled/NotificationBox.js";
import { SideDetailsAnimateContext } from "../contexts/SideDetailsAnimateContext";

// simple data example - Check out https://www.material-react-table.com/docs/examples/remote for a more complex example
// Material React Table docs: https://www.material-react-table.com/docs/getting-started/install

const OrderTable = () => {
	const { animateSideDetailsIn } = React.useContext(SideDetailsAnimateContext);
	const { data: rows, isLoading, isError, isSuccess } = useGetOrdersQuery();
	const { setContextValue } = React.useContext(SideDetailsContext);
	const [clickedRowId, setClickedRowId] = React.useState(null);

	React.useEffect(() => {
		const horizontal_scroll = document.querySelector(".ch-table-container");
		isSuccess && horizontal_scroll.children[1].classList.add("scroll-handler");
	}, [isSuccess]);

	const columns = useMemo(
		() => [
			{
				accessorKey: "id",
				header: "ID",
				size: 50,
			},
			{
				accessorFn: (row) => formatDate(row.dateTimeStamp),
				id: "dateTimeStamp",
				header: "Datum porudžbine",
			},
			{
				accessorKey: "buyer",
				header: "Kupac",
			},
			{
				accessorFn: (row) => formatAddress(row.streetName, row.streetNumber, row.city),
				id: "address",
				header: "Adresa isporuke",
			},
			{
				accessorKey: "status",
				header: "Status",
				// enableSorting: false,
				size: 70,
				Cell: ({ cell, row }) => (
					<Chip
						label={cell.getValue()}
						color={row.original.color}
						style={{ width: "95px" }}
					/>
				),
			},
			{
				// accessorFn: (row) => formatCurrency(row.price),
				accessorFn: (row) => formatCurrency(totalValue(row.order.body)),
				id: "price",
				header: "Vrednost",
				size: 90,
			},
		],
		[],
	);

	function buyerAddress(streetName, streetNumber, city) {
		if (streetNumber === null || streetNumber === "" || streetNumber === undefined) {
			return `${streetName}, ${city}`;
		}
		return `${streetName} ${streetNumber}, ${city}`;
	}
	function zipCity(zipCode, city) {
		if (zipCode === null || zipCode === "" || zipCode === undefined) {
			return `${city}`;
		}
		return `${zipCode} ${city}`;
	}

	const handleTableRowClick = React.useCallback(
		(row) => {
			// ! Session storage is used for PDF Export and for total value in OrderDetails,
			// ! but it needs to be removed and instead use the RTK Query cache

			// session storage is used instead, and it works like a charm :) as always
			sessionStorage.setItem("order_id", row.original.id);
			sessionStorage.setItem("dateTimeStamp", row.original.dateTimeStamp);
			sessionStorage.setItem(
				"buyer_address",
				buyerAddress(row.original.streetName, row.original.streetNumber, row.original.city),
			);
			sessionStorage.setItem("buyer_name", row.original.buyer);
			sessionStorage.setItem(
				"street_name_number",
				`${row.original.streetName} ${row.original.streetNumber ?? ""}`,
			);
			sessionStorage.setItem("zip_city", zipCity(row.original.zipCode, row.original.city));
			sessionStorage.setItem("order", JSON.stringify(row.original.order.body));
			// ! let's figure out how to set this to the context or send it as a prop to OrderDetails
			sessionStorage.setItem("total_value", formatCurrency(totalValue(row.original.order.body)));
			setContextValue(row.original); // ! This is the whole data object of the clicked row
			setContextValue((prev) => ({
				...prev,
				total_value: formatCurrency(totalValue(row.original.order.body)),
			}));
			setClickedRowId(row.id);

			// open side details on click on any OrderTable row
			animateSideDetailsIn();
		},
		[animateSideDetailsIn, setContextValue],
	);

	// TODO: check how to change the logic of a clicked row! Maybe Material Table has something in the docs about COLORING the SELECTED row
	const rowClassName = (rowId) => {
		return rowId === clickedRowId ? "clicked-row" : "";
	};

	if (isLoading) {
		// render a loading indicator here
		return <LoadingNotificationBox>Loading...</LoadingNotificationBox>;
	}

	if (isError) {
		// render an error message here
		return <ErrorNotificationBox>Error loading data</ErrorNotificationBox>;
	}

	return (
		<MaterialReactTable
			localization={MRT_Localization_SR_LATN_RS}
			columns={columns}
			data={rows}
			muiTablePaperProps={{
				//change the mui box shadow
				elevation: 0,
				//customize paper styles
				sx: {
					borderRadius: "0",
					// borderTop: '1px solid #e0e0e0',
					borderBottom: "1px solid #e0e0e0",
					cursor: "pointer",
				},
				className: "ch-table-container",
			}}
			enableColumnResizing
			columnResizeMode="onChange"
			muiTableBodyRowProps={({ row }) => ({
				onClick: () => handleTableRowClick(row), //consider implementing class animate-side-details here, instead in App.js
				className: rowClassName(row.id),
			})}
		/>
	);
};

export default OrderTable;
