/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab, Box, ThemeProvider, createTheme } from "@mui/material";
import Dashboard from "../dashboard/Dashboard";

const darkTheme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#f1801e",
		},
		background: {
			default: "#000000",
			paper: "#000000",
		},
		text: {
			primary: "#ffffff",
		},
	},
});

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	if (value !== index) {
		return null;
	}

	return (
		<Box
			role="tabpanel"
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			sx={{
				flexGrow: 1,
				overflowY: "auto",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
			{...other}
		>
			{children}
		</Box>
	);
};

const TabWrapper = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [value, setValue] = useState(() => {
		const params = new URLSearchParams(location.search);
		return parseInt(params.get("tab") || "0", 10);
	});

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		if (!params.get("tab")) {
			navigate("?tab=0", { replace: true });
		}
	}, [location.search, navigate]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		navigate(`?tab=${newValue}`);
	};

	return (
		<ThemeProvider theme={darkTheme}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100vh",
					bgcolor: "background.default",
					color: "text.primary",
				}}
			>
				<Box
					sx={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						zIndex: 1000,
						bgcolor: "background.default",
					}}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
						TabIndicatorProps={{
							style: {
								backgroundColor: "#f1801e",
							},
						}}
						sx={{
							"& .MuiTab-root": { color: "text.primary" },
							"& .Mui-selected": { color: "#f1801e" },
							borderBottom: 1,
							borderColor: "divider",
						}}
					>
						{/* TAB LABELS */}
						<Tab label="Grupe i proizvodi" />
						<Tab label="Korisnici" />
						<Tab label="Tab 3" />
					</Tabs>
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						overflowY: "auto",
						display: "flex",
						flexDirection: "column",
					}}
				>
					{/* TAB PANELS */}
					<TabPanel
						value={value}
						index={0}
					>
						<Dashboard />
					</TabPanel>
					<TabPanel
						value={value}
						index={1}
					>
						Korisnici ce se uređivati ovde
					</TabPanel>
					<TabPanel
						value={value}
						index={2}
					>
						Treći tab je ovde testa radi. Možda će se ovde nalaziti neki drugi sadržaj. A možda
						treći tab neće ni postojati.
					</TabPanel>
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default TabWrapper;
