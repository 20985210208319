/** @format */

import { styled } from "@mui/material/styles";

const StyledDescription = styled("span")({
	paddingLeft: "10px",
	width: "75%",
	fontSize: "14px",
	fontWeight: "400",
	"@media (max-width: 1015px)": {
		display: "none", // Hide the StyledDescription below 750px screen width
	},
});

const FormFieldDescription = ({ descriptionText }) => {
	return <StyledDescription>{descriptionText}</StyledDescription>;
};

export default FormFieldDescription;
