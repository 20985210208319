/** @format */

import { useState, forwardRef } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormFieldDescription from "./FormFieldDescription";
import FormFieldAndDescriptionWrapper from "./FormFieldAndDescriptionWrapper";
import Label from "./Label";

const StyledLabel = styled("label")(({ disabled }) => ({
	display: "block",
	padding: "10px",
	border: disabled ? "1px solid #242424" : "1px solid orange",
	borderRadius: "8px",
	backgroundColor: disabled ? "#141414" : "black",
	color: "grey",
	cursor: disabled ? "not-allowed" : "pointer",
	"&:hover": {
		borderColor: disabled ? "#ccc" : "blue",
	},
}));

const StyledNoBulletList = styled("ul")({
	listStyleType: "none",
	padding: 0,
});

const StyleNameChangeWrapper = styled("div")({
	display: "flex",
	justifyContent: "space-between",
	marginTop: "10px",
	columnGap: "10px",
	alignItems: "center",
});

const DocumentUpload = forwardRef(({ onDocumentReady, disabled, selectedGroupId }, ref) => {
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");

	const MAX_FILE_SIZE_BYTES = 1048576; // 1MB

	const handleDocumentChange = async (event) => {
		const files = Array.from(event.target.files);
		const allowedTypes = [
			"application/pdf",
			"application/vnd.ms-excel",
			"text/plain",
			"application/msword",
		];

		if (!files || files.length === 0) {
			return; // No files selected
		}

		// Create a temporary set to check for duplicates
		const existingFileNames = new Set(selectedFiles.map((file) => file.file.name));
		const newFiles = [];

		for (const file of files) {
			if (existingFileNames.has(file.name)) {
				setErrorMessage(`File "${file.name}" already exists in the list.`);
				return;
			}

			if (file.size > MAX_FILE_SIZE_BYTES) {
				setErrorMessage(`File "${file.name}" exceeds the maximum allowed size of 1MB.`);
				return;
			}

			if (!allowedTypes.includes(file.type)) {
				setErrorMessage("Only PDF, Excel, text files, and Word documents are allowed.");
				return;
			}

			// If file is valid and not a duplicate, prepare to add it
			const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, ""); // Strip extension
			const fileNameWithGroupId = `${selectedGroupId} - ${fileNameWithoutExtension}`; // Add selectedGroupId
			newFiles.push({ file, name: fileNameWithGroupId });
			existingFileNames.add(file.name); // Add to the set to prevent duplicates
		}

		// If we selected some document files, update the state and clear any error messages
		if (newFiles.length > 0) {
			setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
			setErrorMessage("");
			// Callback receives the updated file list
			onDocumentReady([...selectedFiles, ...newFiles]);
		}
	};

	const handleRemoveFile = (event, index) => {
		event.preventDefault();
		const updatedFiles = [...selectedFiles];
		updatedFiles.splice(index, 1);
		setSelectedFiles(updatedFiles);
		onDocumentReady(updatedFiles);
	};

	return (
		<>
			<Label
				labelTitle="Selektor dokumenata grupe proizvoda"
				disabled={disabled}
			/>
			<FormFieldAndDescriptionWrapper>
				<input
					name="document"
					type="file"
					accept=".pdf,.xls,.xlsx,.txt,.doc,.docx"
					onChange={handleDocumentChange}
					style={{ display: "none" }}
					id="documentInput"
					multiple // Allow multiple file selection
					disabled={disabled}
				/>
				{/* Custom button */}
				<div style={{ width: "-webkit-fill-available" }}>
					<StyledLabel
						htmlFor="documentInput"
						disabled={disabled}
					>
						Izaberi dokument(e)
					</StyledLabel>
					{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
					{/* Display selected files */}
					{selectedFiles.length > 0 && (
						<StyledNoBulletList>
							{selectedFiles.map((selectedFile, index) => (
								<li key={index}>
									<StyleNameChangeWrapper>
										{selectedFile.name}
										<IconButton onClick={(e) => handleRemoveFile(e, index)}>
											<DeleteForeverIcon color="error" />
										</IconButton>
									</StyleNameChangeWrapper>
								</li>
							))}
						</StyledNoBulletList>
					)}
				</div>
				<FormFieldDescription descriptionText="Polje za odabir eventualnih dokumenata grupe proizvoda. Dokumenti će biti vidljivi na stranici grupe proizvoda gde će dalje biti dostupni download-u." />
			</FormFieldAndDescriptionWrapper>
		</>
	);
});

export default DocumentUpload;
