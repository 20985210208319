/** @format */

const VerticalSeparator = ({ size }) => {
	return (
		<div
			style={{
				height: size,
			}}
		/>
	);
};

export default VerticalSeparator;
