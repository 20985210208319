/** @format */

import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import InfoCards from "./InfoCards";
import Chip from "@mui/material/Chip";
import { SideDetailsContext } from "../contexts/SideDetailsContext";
// TODO: this one should be moved to some utils folder
// Material React Table Translations
import { MRT_Localization_SR_LATN_RS } from "../utils/MRT_Localization_SR_LATN_RS.js";
// import { rows } from "../temp_data/invoices_data"; // ! WE DON'T NEED THIS DUMMY DATA ANYMORE
// import { useGetInvoicesQuery } from "../api/testApi.js"; // ! NOW WE USE REAL DATA FROM API
import { useGetInvoicesQuery } from "../api/ordersApi.js"; // ! NOW WE USE REAL DATA FROM API
import { LoadingNotificationBox, ErrorNotificationBox } from "../shared/styled/NotificationBox.js";
import { totalValue } from "../shared/utils/totals.js";
import { SideDetailsAnimateContext } from "../contexts/SideDetailsAnimateContext";

const formatDate = (date) => {
	const parsedDate = new Date(date);
	return parsedDate.toLocaleString("sr", {
		day: "numeric",
		month: "numeric",
		year: "numeric",
	});
};

const formatCurrency = (value) => {
	return value.toLocaleString("sr", {
		style: "currency",
		currency: "RSD",
		minimumFractionDigits: 2,
	});
};

// simple data example - Check out https://www.material-react-table.com/docs/examples/remote for a more complex example
// Material React Table docs: https://www.material-react-table.com/docs/getting-started/install

const InvoicesTable = () => {
	const { animateSideDetailsIn } = React.useContext(SideDetailsAnimateContext);
	const { data: rows, isLoading, isError, isSuccess } = useGetInvoicesQuery();
	const { setContextValue } = React.useContext(SideDetailsContext);
	const [clickedRowId, setClickedRowId] = React.useState(null);

	React.useEffect(() => {
		const horizontal_scroll = document.querySelector(".ch-table-container");
		isSuccess && horizontal_scroll.children[1].classList.add("scroll-handler");
	}, [isSuccess]);

	const columns = useMemo(
		() => [
			{
				accessorKey: "id",
				header: "ID",
				size: 50,
			},
			{
				accessorKey: "invoice_number",
				header: "Broj računa",
			},
			{
				accessorFn: (row) => formatDate(row.dateTimeStamp), // dateTimeStamp in OrderTable. Should be generic and same everywhere, like: dateTimeStamp
				id: "dateTimeStamp",
				header: "Datum računa",
			},
			{
				accessorFn: (row) => formatDate(row.payment_due_date),
				id: "payment_due_date",
				header: "Valuta",
			},
			{
				// accessorFn: (row) => formatCurrency(row.amount),
				accessorFn: (row) => formatCurrency(totalValue(row.order.body)),
				id: "amount",
				header: "Iznos",
				size: 90,
			},
			{
				accessorFn: (row) => formatCurrency(row.paid),
				id: "paid_amount",
				header: "Uplaceno",
				size: 90,
			},
			{
				accessorFn: (row) => formatCurrency(row.unpaid),
				id: "unpaid_amount",
				header: "Neplaceno",
				size: 90,
			},
			{
				accessorKey: "status",
				header: "Status",
				// enableSorting: false,
				size: 70,
				Cell: ({ cell, row }) => (
					<Chip
						label={cell.getValue()}
						color={row.original.color}
						style={{ width: "95px" }}
					/>
				),
			},
		],
		[],
	);

	const handleTableRowClick = React.useCallback(
		(row) => {
			setClickedRowId(row.id);
			setContextValue(row.original);

			// open side details on click on any OrderTable row
			animateSideDetailsIn();
		},
		[animateSideDetailsIn, setContextValue],
	);
	const rowClassName = (rowId) => {
		return rowId === clickedRowId ? "clicked-row" : "";
	};

	const cardsData = [
		{
			title: "Ukupno zaduženje",
			number: 123,
		},
		{
			title: "Dospele obaveze",
			number: 456,
		},
		{
			title: "Nedospele obaveze",
			number: 789,
		},
	];

	if (isLoading) {
		// render a loading indicator here
		return <LoadingNotificationBox>Loading...</LoadingNotificationBox>;
	}

	if (isError) {
		// render an error message here
		return <ErrorNotificationBox>Error loading data</ErrorNotificationBox>;
	}

	return (
		<>
			<InfoCards cardsData={cardsData} />
			<MaterialReactTable
				localization={MRT_Localization_SR_LATN_RS}
				columns={columns}
				data={rows}
				muiTablePaperProps={{
					//change the mui box shadow
					elevation: 0,
					//customize paper styles
					sx: {
						borderRadius: "0",
						borderBottom: "1px solid #e0e0e0",
						cursor: "pointer",
					},
					className: "ch-table-container",
				}}
				enableColumnResizing
				columnResizeMode="onChange"
				muiTableBodyRowProps={({ row }) => ({
					onClick: () => handleTableRowClick(row), //consider implementing class animate-side-details here, instead in App.js
					className: rowClassName(row.id),
				})}
			/>
		</>
	);
};

export default InvoicesTable;
