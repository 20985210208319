import React, { createContext, useContext, useState } from 'react';

const OrderModalContext = createContext();

export const OrderModalProvider = ({ children }) => {
	const [shouldOpenModal, setShouldOpenModal] = useState(false);
	const [isCartEnabled, setIsCartEnabled] = useState(false);

	const openOrderModal = () => {
		if (isCartEnabled) {
			setShouldOpenModal(true);
		}
	};

	return (
		<OrderModalContext.Provider value={{
			openOrderModal,
			shouldOpenModal,
			setShouldOpenModal,
			isCartEnabled,
			setIsCartEnabled
		}}>
			{children}
		</OrderModalContext.Provider>
	);
};

export const useOrderModal = () => useContext(OrderModalContext);