import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const BASE_API_URL = "https://backend-b2b.central-ch.com/scripts/api";

export const testApi = createApi({
	reducerPath: "testApi",
	baseQuery: fetchBaseQuery({ baseUrl: BASE_API_URL }),
	endpoints: (builder) => ({
		getOrders: builder.query({
			query: () => {
				return "orders"
			},
		}),

		getFinancialState: builder.query({
			query: () => {
				return "financial-state"
			},
		}),
		getInvoices: builder.query({
			query: () => {
				return "invoices"
			},
		}),
		getPriceList: builder.query({
			query: () => {
				return "price-list"
			},
		}),
		getMenuData: builder.query({
			query: () => "menu_data.php",
			// query: () => fetch("https://backend-b2b.central-ch.com/scripts/api/menu_data.php").then(res => res.json()),
			// query: () => ({ url: "menu_data.php", method: "GET" }), // we can go without GET here
		}),

		// TODO: The customer_id is hardcoded to Z067, but it should be dynamic and should come from the user's jwt token
		/**
		 * and for it to come from the token, we need to save the token to db when user registers
		 * there are old users that reside in the b2b database, and them to will be registered to the new db through the dashboard
		 * new users need to be registered in the b2b first and then treated as old users
		 * basically we take list users from b2b, add passwords to them, and then add them to the new db
		 * we might also add address because we will use it for the delivery when making orders
		 */
		getProducts: builder.query({
			query: (groupID) => `products.php?group_id=${groupID}&customer_id=Z067`,
		}),
		getGroups: builder.query({
			query: () => "get_groups.php",
		}),
		getProductsForDashboard: builder.query({
			query: (selectedGroupId) => `return_products_for_dashboard.php?selectedGroupId=${selectedGroupId}`,
		}),
		submitFormData: builder.mutation({
			query: (formData) => ({
				url: 'dashboard_files_upload.php',
				method: 'POST',
				body: formData,
			}),
		}),
		submitLoginFormData: builder.mutation({
			query: (formData) => ({
				url: 'login.php',
				method: 'POST',
				body: formData,
			}),
		}),
	}),
});

// export const { useGetOrdersQuery, useGetFinancialStateQuery, useGetInvoicesQuery, useGetPriceListQuery, useGetMenuDataQuery } = testApi;
export const {
	useGetMenuDataQuery,
	useGetProductsQuery,
	useGetGroupsQuery,
	useGetProductsForDashboardQuery,
	useSubmitFormDataMutation, // TODO: Dashboard form submit
	useSubmitLoginFormDataMutation
} = testApi;