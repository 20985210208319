/** @format */

export const formatAddress = (streetName, streetNumber, city) => {
	if (streetNumber === null || streetNumber === "null") {
		return `${streetName}, ${city}`;
	}
	return `${streetName} ${streetNumber}, ${city}`;
};

export const formatCurrency = (value) => {
	return value.toLocaleString("sr", {
		style: "currency",
		currency: "RSD",
		minimumFractionDigits: 2,
	});
};

export const formatDate = (date) => {
	const parsedDate = new Date(date);
	return parsedDate.toLocaleString("sr", {
		day: "numeric",
		month: "numeric",
		year: "numeric",
	});
};

