/** @format */

import { styled } from "@mui/material/styles";

const StyledWrapper = styled("div")({
	borderRadius: "9px",
	display: "flex",
	columnGap: "10px",
	// minHeight: "73px",
});

const FormFieldAndDescriptionWrapper = ({ children }) => {
	return <StyledWrapper>{children}</StyledWrapper>;
};

export default FormFieldAndDescriptionWrapper;
