/** @format */

import { useState } from "react";
import { styled } from "@mui/material/styles";
import FormFieldDescription from "./FormFieldDescription";
import FormFieldAndDescriptionWrapper from "./FormFieldAndDescriptionWrapper";
import Label from "./Label";
import imageCompression from "compressorjs";

const StyledLabel = styled("label")(({ disabled }) => ({
	display: "block",
	padding: "10px",
	border: disabled ? "1px solid #242424" : "1px solid orange",
	borderRadius: "8px",
	backgroundColor: disabled ? "#141414" : "black",
	color: "grey",
	cursor: disabled ? "not-allowed" : "pointer",
	"&:hover": {
		borderColor: disabled ? "#ccc" : "blue",
	},
}));

function GroupImageCompressAndUpload({ onImageSelect, disabled }) {
	const [errorMessage, setErrorMessage] = useState("");

	const MAX_FILE_SIZE_BYTES = 1048576; // 1MB
	const MAX_COMPRESSED_SIZE_KB = 500; // 500KB

	const handleImageChange = async (event) => {
		const file = event.target.files && event.target.files[0];
		const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];

		if (!file) {
			return; // No file selected
		}

		if (file.size > MAX_FILE_SIZE_BYTES) {
			setErrorMessage("Veličina fajla prelazi dozvoljeni limit (1MB). Izaberite manji fajl.");
		} else if (!allowedTypes.includes(file.type)) {
			setErrorMessage("Samo JPG, PNG, GIF, and WebP fajlovi su dozvoljeni.");
		} else {
			// Compress the image
			const compressedImage = await compressImage(file);

			// Check compressed image size
			if (compressedImage.size > MAX_COMPRESSED_SIZE_KB * 1024) {
				setErrorMessage(`Compressed image size exceeds ${MAX_COMPRESSED_SIZE_KB}KB.`);
			} else {
				onImageSelect(compressedImage);
				setErrorMessage("");
			}
		}
	};

	const compressImage = (image) => {
		return new Promise((resolve, reject) => {
			new imageCompression(image, {
				maxWidth: 800,
				maxHeight: 600,
				quality: 0.73, // Adjust quality level as needed
				success(compressedImage) {
					resolve(compressedImage);
				},
				error(error) {
					reject(error);
				},
			});
		});
	};

	return (
		<>
			<Label
				labelTitle="Selektor fotografije grupe proizvoda"
				disabled={disabled}
			/>
			<FormFieldAndDescriptionWrapper>
				<input
					type="file"
					accept="image/*"
					onChange={handleImageChange}
					style={{ display: "none" }}
					id="fileInput"
					disabled={disabled}
				/>
				{/* Custom button */}
				<div style={{ width: "-webkit-fill-available" }}>
					<StyledLabel
						htmlFor="fileInput"
						disabled={disabled}
					>
						Izaberi fotografiju grupe proizvoda
					</StyledLabel>
					{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
				</div>
				<FormFieldDescription descriptionText="Polje za odabir fotografije grupe proizvoda. Dozvoljeni formati: JPG, PNG, GIF, WebP. Maksimalna veličina: 1MB." />
			</FormFieldAndDescriptionWrapper>
		</>
	);
}

export default GroupImageCompressAndUpload;
