/** @format */

import { useState } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	borderRadius: 2,
	transform: "translate(-50%, -50%)",
	width: "80%",
	maxWidth: "85vh",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	maxHeight: "90vh",
	overflow: "auto",
};

const serbianCurrencyFormat = (num) => {
	return new Intl.NumberFormat("sr-RS", {
		// When we need to display currency, we can use the following options
		// style: "currency",
		// currency: "RSD",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(num);
};

const validateAddress = (address) => {
	if (!address.trim()) {
		return "Adresa je obavezna.";
	}
	if (address.trim().length < 10) {
		return "Adresa mora imati najmanje 10 karaktera.";
	}
	if (address.trim().length > 200) {
		return "Adresa ne sme biti duža od 200 karaktera.";
	}
	// Character set restriction
	const allowedPattern = /^[a-zA-ZšđčćžŠĐČĆŽ0-9\s.,#-]+$/;
	if (!allowedPattern.test(address)) {
		return "Adresa sadrži nedozvoljene karaktere.";
	}
	return ""; // No error
};

const OrderModal = ({ open, handleClose, orderItems, totalWithoutPDV }) => {
	const [shippingAddress, setShippingAddress] = useState("");
	const [shippingAddressError, setShippingAddressError] = useState("");

	const handleConfirmOrder = () => {
		const addressError = validateAddress(shippingAddress);
		if (addressError) {
			setShippingAddressError(addressError);
			return; // Don't proceed if there's an error
		}

		// Proceed with order confirmation
		console.log("Order Items:", orderItems);
		console.log("Total Price:", totalWithoutPDV);
		console.log("Shipping Address:", shippingAddress);
		// TODO: Here we will send the order to the server
		handleClose();
	};

	const handleAddressChange = (e) => {
		const newAddress = e.target.value;
		setShippingAddress(newAddress);

		// Perform validation
		const error = validateAddress(newAddress);
		setShippingAddressError(error);
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="order-modal-title"
			aria-describedby="order-modal-description"
		>
			<Box sx={style}>
				<Typography
					id="order-modal-title"
					variant="h6"
					component="h2"
				>
					Your Order
				</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>ID broj</TableCell>
							<TableCell align="right">Naziv proizvoda</TableCell>
							<TableCell align="right">Količina</TableCell>
							<TableCell align="right">Neto cena</TableCell>
							<TableCell align="right">Vrednost</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{orderItems.map((item) => {
							const name = item.product_name;
							const quantity = parseInt(item.order_quantity) || 0;
							const price = parseFloat(item.net_price) || 0;
							const total = quantity * price;
							return (
								<TableRow key={item.id}>
									<TableCell
										component="th"
										scope="row"
									>
										{item.id}
									</TableCell>
									<TableCell align="right">{name}</TableCell>
									<TableCell align="right">{quantity}</TableCell>
									<TableCell align="right">{serbianCurrencyFormat(price)}</TableCell>
									<TableCell align="right">{serbianCurrencyFormat(total)}</TableCell>
								</TableRow>
							);
						})}
						{/* Total without PDV */}
						<TableRow>
							<TableCell
								colSpan={4}
								align="right"
							>
								Ukupno bez PDV-a:
							</TableCell>
							<TableCell align="right">{serbianCurrencyFormat(totalWithoutPDV)}</TableCell>
						</TableRow>
						{/* PDV only */}
						<TableRow>
							<TableCell
								colSpan={4}
								align="right"
							>
								PDV (20%):
							</TableCell>
							<TableCell align="right">{serbianCurrencyFormat(totalWithoutPDV * 0.2)}</TableCell>
						</TableRow>
						{/* Total with PDV */}
						<TableRow>
							<TableCell
								colSpan={4}
								align="right"
							>
								Ukupno sa PDV-om:
							</TableCell>
							<TableCell align="right">{serbianCurrencyFormat(totalWithoutPDV * 1.2)}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<TextField
					fullWidth
					label="Adresa za isporuku"
					value={shippingAddress}
					onChange={handleAddressChange}
					onBlur={() => setShippingAddressError(validateAddress(shippingAddress))}
					error={!!shippingAddressError}
					helperText={shippingAddressError}
					multiline
					rows={2}
					margin="normal"
				/>
				<Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
					<Button
						onClick={handleClose}
						sx={{ mr: 1 }}
					>
						Otkaži
					</Button>
					<Button
						onClick={handleConfirmOrder}
						variant="contained"
						// color="primary"
						sx={{ backgroundColor: "orange", "&:hover": { backgroundColor: "darkorange" } }}
					>
						Završi porudžbinu
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default OrderModal;
