/** @format */

import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import InfoCards from "./InfoCards";
// Material React Table Translations
import { MRT_Localization_SR_LATN_RS } from "../utils/MRT_Localization_SR_LATN_RS.js";
// import { rows } from "../temp_data/financial_state_data"; // ! WE DON'T NEED THIS DUMMY DATA ANYMORE
// import { useGetFinancialStateQuery } from "../api/testApi.js"; // ! NOW WE USE REAL DATA FROM API
import { useGetFinancialStateQuery } from "../api/ordersApi.js"; // ! NOW WE USE REAL DATA FROM API
import { LoadingNotificationBox, ErrorNotificationBox } from "../shared/styled/NotificationBox.js";
// import Chip from "@mui/material/Chip";
import { formatDate, formatCurrency } from "../shared/utils/formatting.js";

// simple data example - Check out https://www.material-react-table.com/docs/examples/remote for a more complex example
// Material React Table docs: https://www.material-react-table.com/docs/getting-started/install

const OrderTable = () => {
	const { data: rows, isLoading, isError, isSuccess } = useGetFinancialStateQuery();

	React.useEffect(() => {
		const horizontal_scroll = document.querySelector(".ch-table-container");
		isSuccess && horizontal_scroll.children[1].classList.add("scroll-handler");
	}, [isSuccess]);

	const columns = useMemo(
		() => [
			{
				accessorKey: "id",
				header: "ID",
				size: 50,
			},
			{
				accessorKey: "document_number",
				header: "Broj dokumenta",
			},
			{
				accessorFn: (row) => formatDate(row.date),
				id: "date",
				header: "Datum",
			},
			{
				accessorFn: (row) => formatDate(row.payment_due_date),
				id: "payment_due_date",
				header: "Valuta",
			},
			{
				accessorFn: (row) => formatCurrency(row.owes_amount),
				id: "owes_amount",
				header: "Duguje",
				size: 90,
			},
			{
				accessorFn: (row) => formatCurrency(row.demands_amount),
				id: "demands_amount",
				header: "Potražuje",
				size: 90,
			},
		],
		[],
	);

	const cardsData = [
		{
			title: "Ukupno zaduženje",
			number: 123,
		},
		{
			title: "Dospele obaveze",
			number: 456,
		},
		{
			title: "Nedospele obaveze",
			number: 789,
		},
	];

	if (isLoading) {
		// render a loading indicator here
		return <LoadingNotificationBox>Loading...</LoadingNotificationBox>;
	}

	if (isError) {
		// render an error message here
		return <ErrorNotificationBox>Error loading data</ErrorNotificationBox>;
	}

	return (
		<>
			<InfoCards cardsData={cardsData} />
			<MaterialReactTable
				localization={MRT_Localization_SR_LATN_RS}
				columns={columns}
				data={rows}
				muiTablePaperProps={{
					//change the mui box shadow
					elevation: 0,
					//customize paper styles
					sx: {
						borderRadius: "0",
						borderBottom: "1px solid #e0e0e0",
						cursor: "pointer",
					},
					className: "ch-table-container",
				}}
				enableColumnResizing
				columnResizeMode="onChange"
				// muiTableBodyRowProps={({ row }) => ({
				// 	onClick: () => handleTableRowClick(row), //consider implementing class animate-side-details here, instead in App.js
				// 	className: rowClassName(row.id),
				// })}
			/>
		</>
	);
};

export default OrderTable;
