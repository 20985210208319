/** @format */

import { useState, forwardRef } from "react";
import { styled } from "@mui/material/styles";
import FormFieldDescription from "./FormFieldDescription";
import FormFieldAndDescriptionWrapper from "./FormFieldAndDescriptionWrapper";
import Label from "./Label";
import imageCompression from "compressorjs";

const StyledLabel = styled("label")(({ disabled }) => ({
	display: "block",
	padding: "10px",
	border: disabled ? "1px solid #242424" : "1px solid orange",
	borderRadius: "8px",
	backgroundColor: disabled ? "#141414" : "black",
	color: "grey",
	cursor: disabled ? "not-allowed" : "pointer",
	"&:hover": {
		borderColor: disabled ? "#ccc" : "blue",
	},
}));

const ProductImageCompressAndUpload = forwardRef(({ onProductImagesSelect, disabled }, ref) => {
	const [errorMessage, setErrorMessage] = useState("");

	const MAX_FILE_SIZE_BYTES = 1048576; // 1MB
	const MAX_COMPRESSED_SIZE_KB = 500; // 500KB

	const handleProductImageChange = async (event) => {
		const files = event.target.files;
		const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];

		if (!files || files.length === 0) {
			return; // No file selected
		}

		const selectedProductImages = [];

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (file.size > MAX_FILE_SIZE_BYTES) {
				setErrorMessage("Veličina fajla prelazi dozvoljeni limit (1MB). Izaberite manji fajl.");
				return;
			} else if (!allowedTypes.includes(file.type)) {
				setErrorMessage("Samo JPG, PNG, GIF, and WebP fajlovi su dozvoljeni.");
				return;
			}

			// Compress the image
			const compressedProductImage = await compressImage(file);

			// Check compressed image size
			if (compressedProductImage.size > MAX_COMPRESSED_SIZE_KB * 1024) {
				setErrorMessage(`Kompresovana slika prelazi limit od ${MAX_COMPRESSED_SIZE_KB}KB.`);
				return;
			} else {
				selectedProductImages.push(compressedProductImage);
			}
		}

		// All images are compressed and valid
		onProductImagesSelect(selectedProductImages);
		setErrorMessage("");

		event.target.value = null; // Reset the input field
	};

	const compressImage = (image) => {
		return new Promise((resolve, reject) => {
			new imageCompression(image, {
				maxWidth: 800,
				maxHeight: 600,
				quality: 0.73, // Adjust quality level as needed
				success(compressedProductImage) {
					resolve(compressedProductImage);
				},
				error(error) {
					reject(error);
				},
			});
		});
	};

	return (
		<>
			<Label
				labelTitle="Selektor fotografija proizvoda"
				disabled={disabled}
			/>
			<FormFieldAndDescriptionWrapper>
				<input
					type="file"
					accept="image/*"
					onChange={handleProductImageChange}
					style={{ display: "none" }}
					id="multipleImagesInput"
					multiple
					disabled={disabled}
				/>
				{/* Custom button */}
				<div style={{ width: "-webkit-fill-available" }}>
					<StyledLabel
						htmlFor="multipleImagesInput"
						disabled={disabled}
					>
						Izaberi fotografiju / fotografije samih proizvoda
					</StyledLabel>
					{errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
				</div>
				<FormFieldDescription descriptionText="Polje za odabir fotografija proizvoda. Moguće je birati jednu ili više odjednom, ili iz više puta. Dozvoljeni formati: JPG, PNG, GIF, WebP. Maksimalna veličina: 1MB." />
			</FormFieldAndDescriptionWrapper>
		</>
	);
});

export default ProductImageCompressAndUpload;
